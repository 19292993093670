/** CONSTANTS */
import { fundingDefaultMainBorrowerMonthlyIncomes, fundingDefaultMonthlyCredit } from "domain/funding/funding.constant";

/** INSTANCES */
import { httpAxios } from "infrastructure/instances/httpAxios.instance";

/** MODELS */
import { Funding } from "../../../domain/funding/funding.model";

/** PORTS */
import { FundingPort } from "../../../infrastructure/ports/funding.repository";

/** SERVICES */
import { FundingService } from "domain/funding/funding.service";

const client = httpAxios;

export const updateFundingUseCase = async (
	id: string,
	payload: Pick<Funding, "propertyPrice" | "contribution" | "duration" | "propertyType">
): Promise<Funding> => {
	Object.keys(payload).forEach((key) => {
		const value =
			payload[
				key as keyof Pick<
					Funding,
					"propertyPrice" | "contribution" | "duration"
				>
			];

		if (typeof value === "number") FundingService.checkValue(key, value);
	});

	const contribution =
		payload.contribution === 0
			? FundingService.calculateDefaultContribution(payload.propertyPrice)
			: payload.contribution;

	return FundingPort(client).create({
		...payload,
		id,
		contribution,
		mainBorrowerMonthlyIncomes: fundingDefaultMainBorrowerMonthlyIncomes,
		monthlyCredits: fundingDefaultMonthlyCredit,
	});
};
