import { useCallback, useEffect, useState } from "react";
import "./MyDurationModal.scss";

/** COMPONENTS */
import MyModal from "../MyModal/MyModal.component";
import FundingDurationSelector from "ui/components/funding/FundingDurationSelector/FundingDurationSelector.component";

/** MODELS */
import { Duration } from "domain/types";

/** SVGs */
import { ReactComponent as Close } from "ui/assets/svg/close.svg";
import { ReactComponent as RightArrow } from "ui/assets/svg/white-right-arrow.svg";

type MyDurationModalProps = {
	readonly icon: JSX.Element;
	readonly handleClose: () => void;
	readonly value: Duration;
	readonly CBHandleClick: (value: Duration) => void;
};


const MyDurationModal = ({
	icon,
	value,
	handleClose,
	CBHandleClick,
}: MyDurationModalProps) => {
	const [selected, setSelected] = useState<Duration>(value);

	useEffect(() => {
		setSelected(value);
	}, [value]);

	const handleClick = useCallback((duration: Duration) => () => {
		setSelected(duration)
	}, [])

	const handleSave = () => CBHandleClick(selected);

	return (
		<MyModal layout="duration-modal" handleClose={handleClose}>
			<button className="close" onClick={handleClose}>
				{<Close />}
			</button>

			<div className="icon">{icon}</div>

			<FundingDurationSelector value={selected} CBhandleClick={handleClick} />

			<button className="save" onClick={handleSave}>
				<span>ENREGISTRER</span>
				<RightArrow />
			</button>
		</MyModal>
	);
};

export default MyDurationModal;
