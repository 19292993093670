import "./FundingResult.scss";

/** COMPONENTS */
import FundingGoToSimulation from "../FundingGoToSimulation/FundingGoToSimulation.component";

/** HOOKS */
import { useBreakpoint } from "ui/hooks/useBreakpoint.hook";

/** MODELS */
import { Funding } from "domain/funding/funding.model";

/** UTILS */
import { numberWithSpaces } from "ui/util";

type FundingResultProps = {
	readonly funding: Funding;
	readonly CBHandleClick: () => void;
};

const FundingResult = ({ funding, CBHandleClick }: FundingResultProps) => {
	const { isXSmall, isSmall } = useBreakpoint();

	return (
		<div className="funding-result">
			<h3>Mensualité estimée</h3>

			<div>
				<p>
					<span className="value">
						{numberWithSpaces(Math.ceil(funding.monthlyPayment))}€
					</span>
					<span>/mois*</span>
				</p>

				<div className="rate">
					<span>Taux appliqué {funding.rate * 100}%</span>
				</div>
			</div>

			{!isXSmall && !isSmall && (
				<FundingGoToSimulation
					disabled={funding?.id == null}
					CBHandleClick={CBHandleClick}
				/>
			)}
		</div>
	);
};

export default FundingResult;
