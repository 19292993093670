import { useEffect } from "react";

/** COMPONENTS */
import Layout from "./components/Layout/Layout.component";

/** PAGES */
import FundingPage from "./pages/funding/Funding.page";

/** REACT ROUTER DOM */
import { Navigate, Route, Routes } from "react-router-dom";

/** REACT GA */
import ReactGA from 'react-ga';

/** REACT GTM MODULE */
import TagManager from "react-gtm-module";

ReactGA.initialize(process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS ?? "");

const App = () => {
	useEffect(() => {
		TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GTM_TAG ?? "" });
	}, []);

	useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

	return (
		<Routes>
			<Route element={<Layout />}>
				<Route path="/funding" element={<FundingPage />} />
				<Route path="" element={<Navigate to="/funding" replace={true} />} />
			</Route>
		</Routes>
	);
};

export default App;
