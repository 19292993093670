import { ChangeEvent, useEffect, useState } from "react";
import "./MyInput.scss";

/** UTILS */
import { numberWithSpaces } from "ui/util";

type MyInputProps = {
	readonly disabled?: boolean;
	readonly CBHandleBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
	readonly CBHandleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
	readonly CBHandleEnter?: () => void;
	readonly name: string;
	readonly placeholder: string;
	readonly type?: "text" | "number";
	readonly unit?: string;
	readonly value: string | number;
	readonly displayBorder?: boolean;
	readonly autoFocus?: boolean;
};

const MyInput = ({
	disabled = false,
	CBHandleBlur,
	CBHandleChange,
	CBHandleEnter,
	name,
	placeholder,
	type = "text",
	unit,
	value,
	displayBorder = true,
	autoFocus = false,
}: MyInputProps) => {
	const [inputValue, setInputValue] = useState<number | string>("");

	useEffect(() => {
		setInputValue(value);
	}, [value]);

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		if (CBHandleChange) {
			CBHandleChange(event);
		} else {
			setInputValue(event.target.value);
		}
	};

	const handleBlur = (event: ChangeEvent<HTMLInputElement>) => {
		if (CBHandleBlur) {
			CBHandleBlur(event);
		}
	};

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter" && CBHandleEnter) CBHandleEnter();
	};

	return (
		<div className={`my-input ${displayBorder ? "border" : "no-border"}`}>
			<input
				enterKeyHint="enter"
				autoFocus={autoFocus}
				name={name}
				placeholder={placeholder}
				disabled={disabled}
				onChange={handleChange}
				value={type === "number" ? numberWithSpaces(inputValue) : inputValue}
				onBlur={handleBlur}
				onKeyDown={handleKeyDown}
			/>
			{unit && <span className="unit"> {unit}</span>}
		</div>
	);
};

export default MyInput;
