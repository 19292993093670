import "./Layout.scss";

/** REACT ROUTER DOM */
import { Outlet } from "react-router-dom";

const Layout = () => (
	<main>
		<Outlet />
	</main>
);

export default Layout;
