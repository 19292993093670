import { ChangeEvent, useState } from "react";
import "./MyInputModal.scss";

/** COMPONENTS */
import MyModal from "../MyModal/MyModal.component";
import MyInput from "../MyInput/MyInput.component";

/** SVGs */
import { ReactComponent as Close } from "ui/assets/svg/close.svg";
import { ReactComponent as RightArrow } from "ui/assets/svg/white-right-arrow.svg";

type MyInputModalProps = {
	readonly icon: JSX.Element;
	readonly CBHandleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
	readonly CBHandleBlur: (event: ChangeEvent<HTMLInputElement>) => void;
	readonly CBHandleClose: () => void;
	readonly name: string;
	readonly value: string | number;
};

const MyInputModal = ({
	icon,
	name,
	value,
	CBHandleBlur,
	CBHandleClose,
}: MyInputModalProps) => {
	const [event, setEvent] = useState<ChangeEvent<HTMLInputElement> | null>(
		null
	);

	const handleChange = (event: ChangeEvent<HTMLInputElement>) =>
		setEvent(event);

	const handleSubmit = () => {
		if (event) {
			CBHandleBlur(event);
		}
		CBHandleClose()
	};

	return (
		<MyModal layout="input-modal" handleClose={CBHandleClose}>
			<button className="close" onClick={CBHandleClose}>
				{<Close />}
			</button>

			<div className="icon">{icon}</div>

			<MyInput
				autoFocus
				type="number"
				name={name}
				value={event ? event.target.value : value}
				placeholder="0"
				CBHandleChange={handleChange}
				unit="€"
			/>

			<button className="save" onClick={handleSubmit}>
				<span>ENREGISTRER</span>
				<RightArrow />
			</button>
		</MyModal>
	);
};

export default MyInputModal;
