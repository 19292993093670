/** DTOs */
import { FundingDTO } from "../../domain/funding/funding.dto";

/** MODELS */
import { Funding } from "domain/funding/funding.model";

/** REPOSITORIES */
import { FundingRepository } from "../../domain/funding/funding.entities";
import { HttpRepository } from "../../domain/http/http.repository";

/** SERVICES */
import { FundingService } from "../../domain/funding/funding.service";

const apiUri = process.env.REACT_APP_API_URI;

export const FundingPort = (client: HttpRepository): FundingRepository => ({
	create: async (payload: Partial<Funding>) => {
		const formattedPayload = FundingService.formatToAPI(payload);
		const res: FundingDTO = await client.post(
			`${apiUri}/webappfundings/funding`,
			formattedPayload
		);

		return FundingService.formatFromApi(res);
	},

	update: async (id: string, payload: Partial<Funding>) => {
		const formattedPayload = FundingService.formatToAPI(payload);
		const res: FundingDTO = await client.patch(
			`${apiUri}/webappfundings/funding/${id}`,
			formattedPayload
		);

		return FundingService.formatFromApi(res);
	},
});
