/** AXIOS */
import axios from "axios";

/** REPOSITORIES */
import { HttpRepository } from "../../domain/http/http.repository";

const headers = {
	"Content-Type": "application/json",
};

export const httpAxios: HttpRepository = {
	get: async <T>(path: string, config?: any) => {
		return await axios
			.get(path, {
				headers,
				...config,
			})
			.then((res) => res.data as T);
	},

	patch: async <T>(
		path: string,
		payload: Record<string, any>,
		config?: any
	) => {
		return await axios
			.patch(path, payload, {
				headers,
				...config,
			})
			.then((res) => res.data as T);
	},

	post: async <T>(path: string, payload: Record<string, any>, config?: any) => {
		return await axios
			.post(path, payload, {
				headers,
				...config,
			})
			.then((res) => res.data as T);
	},

	delete: async <T>(path: string, config?: any) => {
		return await axios
			.post(path, {
				headers,
				...config,
			})
			.then((res) => res.data as T);
	},
};
