/** CONSTANTS */
import { PropertyTypes, sources } from "./constants";
import { PropertyTypeDTO } from "./dtos";

/** EXCEPTIONS */
import { InvalidPropertyType } from "./exceptions";

/** TYPES */
import { PropertyType, Source } from "./types";

export const formatPropertyType = (
	type: string | PropertyTypeDTO
): PropertyType => {
	const keys = Object.keys(PropertyTypes);

	if (typeof type === "string") {
		if (keys.includes(type) === false) {
			throw new InvalidPropertyType(type, "formatPropertyType");
		}

		return type as PropertyType;
	} else {
		const values = Object.values(PropertyTypes);
		const index = values.indexOf(type);

		if (index === -1) {
			throw new InvalidPropertyType(type, "formatPropertyType");
		}

		return keys[index] as PropertyType;
	}
};

export const getSource = (source: string): Source => {
	if (sources.includes(source as any)) {
		return source as Source;
	}
	return "widget";
};
