import { Source } from "domain/types";

const floatNumberWithSpaces = (number: number | string): string => {
	let parts = number.toString().split(".");

	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
	return parts.join(".");
};

export const numberWithSpaces = (
	number: number | string,
	numberAfterDecimal: number = 0
): string => {
	if (isNaN(parseFloat(number.toString()))) return "";

	number = number.toString().replace(/\s/g, "");
	number = parseFloat(number).toFixed(numberAfterDecimal);

	if (number.toString().indexOf(".") !== -1) {
		return floatNumberWithSpaces(number);
	}

	return number.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

export const numberWithoutSpaces = (numberToCast: string | number): number => {
	let numberToReturn: number = 0;

	numberToCast = numberToCast.toString().replace(/\s/g, "");

	if (numberToCast.indexOf(".") !== -1) {
		numberToReturn = parseFloat(numberToCast);
	} else {
		numberToReturn = parseInt(numberToCast);
	}

	if (Number.isNaN(numberToReturn)) {
		return 0;
	}
	return numberToReturn;
};

export const getExternalFridaaWebsiteUriBasedOnSource = (
	source: Source
): string | null => {
	switch (source) {
		case "widget-apimo":
			return process.env.REACT_APP_FRIDAA_EXTERNAL_WEBSITE_URI ?? null;
		case "widget-horizio":
			return process.env.REACT_APP_FRIDAA_EXTERNAL_WEBSITE_URI ?? null;
		case "widget-immopop":
			return process.env.REACT_APP_FRIDAA_EXTERNAL_WEBSITE_URI ?? null;
		case "widget":
			return process.env.REACT_APP_FRIDAA_EXTERNAL_WEBSITE_URI ?? null;
		default:
			return process.env.REACT_APP_FRIDAA_EXTERNAL_WEBSITE_URI ?? null;
	}
};

export const openInNewTab = (url: string) => {
	window.open(url, "_blank", "noreferrer");
};
