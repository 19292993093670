import { ReactNode } from "react";
import "./MyModal.scss";

/** RADIX */
import { blackA } from "@radix-ui/colors";
import * as DialogPrimitive from "@radix-ui/react-dialog";

/** STITCHES */
import { styled, keyframes } from "@stitches/react";

const overlayShow = keyframes({
	"0%": { opacity: 0 },
	"100%": { opacity: 1 },
});

const contentShow = keyframes({
	"0%": { opacity: 0, transform: "translate(-50%, -48%) scale(.96)" },
	"100%": { opacity: 1, transform: "translate(-50%, -50%) scale(1)" },
});

const StyledOverlay = styled(DialogPrimitive.Overlay, {
	backgroundColor: blackA.blackA9,
	backdropFilter: "blur(8px)",
	position: "fixed",
	inset: 0,
	"@media (prefers-reduced-motion: no-preference)": {
		animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
	},
});

const StyledContent = styled(DialogPrimitive.Content, {
	backgroundColor: "white",
	borderRadius: 20,
	boxShadow:
		"hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px",
	position: "fixed",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "75vw",
	maxWidth: "450px",
	maxHeight: "85vh",
	padding: 25,
	"@media (prefers-reduced-motion: no-preference)": {
		animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
	},
	"&:focus": { outline: "none" },
});

type ContentProps = {
	readonly children: ReactNode;
};

const Content = ({ children, ...props }: ContentProps) => {
	const { layout }: any = props;

	return (
		<DialogPrimitive.Portal>
			<StyledOverlay />
			<StyledContent id={`my-modal-${layout}`} className="my-modal">{children}</StyledContent>
		</DialogPrimitive.Portal>
	);
};
export const Dialog = DialogPrimitive.Root;
export const DialogTrigger = DialogPrimitive.Trigger;
export const DialogContent = Content;
export const DialogTitle = DialogPrimitive.Title;
export const DialogDescription = DialogPrimitive.Description;
export const DialogClose = DialogPrimitive.Close;

type MyModalProps = {
	readonly title?: string;
	readonly description?: string;
	readonly displayClose?: boolean;
	readonly layout?: string;
	readonly children?: ReactNode;
	readonly handleClose?: () => void;
};

const MyModal = ({
	children,
	title,
	description,
	layout = "default",
	displayClose = false,
	handleClose,
}: MyModalProps) => (
	<DialogPrimitive.Root open={true} onOpenChange={handleClose}>
		<DialogContent {...{ layout: layout }}>
			{title && <DialogTitle>{title}</DialogTitle>}
			{description && <DialogDescription>{description}</DialogDescription>}

			{children}

			{displayClose && (
				<DialogClose asChild id="dialog-close">
					<button>X</button>
				</DialogClose>
			)}
		</DialogContent>
	</DialogPrimitive.Root>
);

export default MyModal;