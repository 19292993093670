/** HOOKS */
import { useMediaType } from "./useMediaType.hook";

export type Breakpoints = {
	isXSmall: boolean;
	isSmall: boolean;
	isMedium: boolean;
	isLarge: boolean;
	isXLarge: boolean;
};

export const useBreakpoint = (): Breakpoints => ({
	isXSmall: useMediaType("(max-width: 480px)"), // Mobile device.
	isSmall: useMediaType("(min-width: 481px) and (max-width: 839px)"), // Tablette device.
	isMedium: useMediaType("(min-width: 840px) and (max-width: 1024px)"), // Small laptop screen.
	isLarge: useMediaType("(min-width: 1025px) and (max-width: 1200px)"), // desktop large screen.
	isXLarge: useMediaType("(min-width: 1201px)"), // Extra large screens.
});