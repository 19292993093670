/** DTOs */
import { PropertyTypeDTO } from "./dtos";

/** TYPES */
import { PropertyType, Source } from "./types";

export const PropertyTypes: Record<PropertyType, PropertyTypeDTO> = {
	OLD: 0,
	OLD_WITH_WORKS: 1,
	LAND_AND_CONSTRUCTION: 2,
	NEW: 3,
};

export const sources: Source[] = [
	"widget-abriculteurs",
	"widget-apimo",
	"widget-horizio",
	"widget-immopop",
	"widget",
];
