import "./FundingGoToSimulation.scss";

/** HOOKS */
import { useBreakpoint } from "ui/hooks/useBreakpoint.hook";

/** SVGs */
import { ReactComponent as Arrow } from "ui/assets/svg/white-right-arrow.svg";

type FundingGoToSimulationProps = {
	readonly disabled: boolean;
	readonly CBHandleClick: () => void;
};

const FundingGoToSimulation = ({
	disabled,
	CBHandleClick,
}: FundingGoToSimulationProps) => {
	const { isXSmall, isSmall } = useBreakpoint();

	return (
		<button
			disabled={disabled}
			className="funding-go-to-simulation"
			onClick={CBHandleClick}
		>
			<span>{!isXSmall && !isSmall && "DÉCOUVRIR"} MA SIMULATION</span>
			<Arrow />
		</button>
	);
};

export default FundingGoToSimulation;
