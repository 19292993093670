import { ChangeEvent, useCallback, useEffect, useState } from "react";
import "./FundingCategories.scss";

/** ASSETS */
import { ReactComponent as Estate } from "ui/assets/svg/icon-land-construction.svg";
import { ReactComponent as Contribution } from "ui/assets/svg/icon-contribution.svg";
import { ReactComponent as Fees } from "ui/assets/svg/icon-fees.svg";
import { ReactComponent as DurationIcon } from "ui/assets/svg/icon-duration.svg";
import { ReactComponent as Edit } from "ui/assets/svg/icon-edit.svg";

/** COMPONENTS */
import MyInputModal from "ui/components/commons/MyInputModal/MyInputModal.component";
import MyInput from "ui/components/commons/MyInput/MyInput.component";
import MyDurationModal from "ui/components/commons/MyDurationModal/MyDurationModal.component";
import FundingDurationSelector from "../FundingDurationSelector/FundingDurationSelector.component";

/* HOOKS */
import { useBreakpoint } from "ui/hooks/useBreakpoint.hook";

/** MODELS */
import { Funding } from "domain/funding/funding.model";

/** UTILS */
import { numberWithoutSpaces } from "ui/util";
import { Duration } from "domain/types";

type Unit = "€" | "ans";
type InputType = "input" | "durationInput";

type FundingCategoryConfig = {
	readonly description: string;
	readonly displayBorder: boolean;
	readonly icon: JSX.Element;
	readonly disabled: boolean;
	readonly label: string;
	readonly name: string;
	readonly unit: Unit;
	readonly type: InputType;
};

type FundingCategoryInputProps = {
	readonly icon: JSX.Element;
	readonly CBHandleChange: (name: string, value: number) => void;
	readonly disabled: boolean;
	readonly displayBorder: boolean;
	readonly name: string;
	readonly value: number;
	readonly unit: Unit;
};

type FundingCategoriesProps = {
	readonly funding: Funding;
	readonly CBHandleChange: (name: string, value: number) => void;
};

const FundingCategoryInput = ({
	disabled,
	CBHandleChange,
	name,
	icon,
	value,
	unit,
	displayBorder
}: FundingCategoryInputProps): JSX.Element => {
	const [inputValue, setInputValue] = useState<number>(value);
	const [displayModal, setDisplayModal] = useState<boolean>(false);
	const { isXSmall, isSmall } = useBreakpoint();

	useEffect(() => setInputValue(value), [value]);

	const handleDisplayModal = () => setDisplayModal((current) => !current);

	const handleClick = () =>
		(isXSmall || isSmall) && disabled === false && handleDisplayModal();

	const handleCloseModal = useCallback(() => {
		if (name !== "duration" && value !== inputValue) {
			CBHandleChange(name, inputValue);
		}
		handleDisplayModal();
	}, [CBHandleChange, name, inputValue, value]);

	const handleBlur = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			CBHandleChange(
				event.target.name,
				numberWithoutSpaces(event.target.value)
			);
		},
		[CBHandleChange]
	);

	const handleChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) =>
			setInputValue(numberWithoutSpaces(event.target.value)),
		[]
	);

	const handleDuration = useCallback(
		(value: Duration) => {
			CBHandleChange("duration", value);
			if (displayModal) handleCloseModal();
		},
		[displayModal, CBHandleChange, handleCloseModal]
	);

	return (
		<div className="input-container">
			{!disabled && (
				<button className="edit" onClick={handleClick}>
					<Edit />
				</button>
			)}

			{name === "duration" && !isXSmall && !isSmall ? (
				<FundingDurationSelector
					value={value as Duration}
					CBhandleClick={(value: Duration) => () => handleDuration(value)}
				/>
			) : (
				<div className="value" onClick={handleClick}>
					<MyInput
						name={name}
						type="number"
						placeholder="0"
						displayBorder={displayBorder}
						unit={unit}
						disabled={isXSmall || isSmall || disabled}
						value={value}
						CBHandleBlur={handleBlur}
					/>
				</div>
			)}

			{displayModal && (
				<>
					{name === "duration" ? (
						<MyDurationModal
							icon={icon}
							value={value as Duration}
							CBHandleClick={handleDuration}
							handleClose={handleCloseModal}
						/>
					) : (
						<MyInputModal
							name={name}
							icon={icon}
							value={inputValue}
							CBHandleBlur={handleBlur}
							CBHandleClose={handleCloseModal}
							CBHandleChange={handleChange}
						/>
					)}
				</>
			)}
		</div>
	);
};

const categories: FundingCategoryConfig[] = [
	{
		description: "Prix du bien affiché sur l'annonce",
		disabled: true,
		displayBorder: false,
		icon: <Estate />,
		label: "Mon bien",
		name: "propertyPrice",
		unit: "€",
		type: "input",
	},
	{
		description: "Frais de notaire, dossier et frais de garantie",
		disabled: true,
		displayBorder: false,
		icon: <Fees />,
		label: "Montant des frais",
		name: "fees",
		unit: "€",
		type: "input",
	},
	{
		description: "Un apport de 15% est recommandé",
		disabled: false,
		displayBorder: true,
		icon: <Contribution />,
		label: "Apport",
		name: "contribution",
		unit: "€",
		type: "input",
	},
	{
		description: "La durée est souvent limitée à 25 ans en banque",
		disabled: false,
		displayBorder: true,
		icon: <DurationIcon />,
		label: "Durée de l'emprunt",
		name: "duration",
		unit: "ans",
		type: "durationInput",
	},
];

const FundingCategories = ({
	funding,
	CBHandleChange,
}: FundingCategoriesProps): JSX.Element => {
	return (
		<div className="funding-categories">
			{categories.map((category, index) => {
				const { icon, label, description, disabled, name, unit, displayBorder } = category;
				const value = funding[name as keyof Funding] as number;

				return (
					<div className="funding-category" key={index}>
						<div className="icon">{icon}</div>

						<div className="details">
							<span className="label">{label}</span>
							<span className="description">{description}</span>
						</div>

						<FundingCategoryInput
							icon={icon}
							CBHandleChange={CBHandleChange}
							displayBorder={displayBorder}
							disabled={disabled}
							name={name}
							value={value}
							unit={unit}
						/>
					</div>
				);
			})}
		</div>
	);
};

export default FundingCategories;
