import "./FundingDurationSelector.scss";

/** MODELS */
import { Duration } from "domain/types";

type FundingDurationSelectorProps = {
	readonly CBhandleClick: (value: Duration) => () => void;
	readonly value: Duration;
};

const durations = [5, 10, 15, 20, 25];

const FundingDurationSelector = ({
	CBhandleClick,
	value,
}: FundingDurationSelectorProps) => (
	<div className="funding-duration-selector">
		{durations.map((duration, index) => (
			<button
				className={duration === value ? "selected" : "not-selected"}
				onClick={CBhandleClick(duration as Duration)}
				key={index}
			>
				<span>{duration}</span>
				<span>ANS</span>
			</button>
		))}
	</div>
);

export default FundingDurationSelector;
